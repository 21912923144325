import { useState } from 'react';
import EmailInput from './EmailInput';
import './Home.css'
import Navbar from './NavBar';

function Home() {

    const [sent, setSent] = useState(false)


    return (
        <>
        <div className="background-image" />
            <Navbar/>
        <div className="content">
            <div className='content-holder'>
                <div className='hero-text'>
                    <span>BoysClub</span><br/> 
                    <span> is Almost Here!</span>
                </div>
                <div className='signUp-box'>
                    <span className='signUp-box-heading'>
                        { sent ? 'See you soon!': 'Sign Up' }
                    </span>
                    <span className='signUp-box-subheading'>
                        { sent ? 'You have been added to the list' : 'Become a founding member!'}
                    </span>
                    <EmailInput isSent={setSent} sent={sent}/>
                </div>
            </div>
        </div>
        </>
        );
}

export default Home;