import React from 'react';
import './Navbar.css';
import logo from './logoWhite.svg'

const Navbar = () => {
  return (
    <header className="navbar">
      <div className="navbar-side"></div>
      <a href="/" className="navbar-logo">
        <img src={logo} alt="logo" />
      </a>
      <nav className="navbar-side navbar-links">
        {/* <a href="/link1" className="navbar-link">About Boys Club</a> */}
        <p href="/" className="navbar-link">Login</p>
      </nav>
    </header>
  );
};

export default Navbar;